import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user"
Vue.use(Vuex)

const getters = {
  videolist: state => state.user.videolist,
}

const store = new Vuex.Store({
    state: {},
    modules: {
        user,
    },
    getters
})

export default store
