const getDefaultState = () => {
    return {
        videolist:[],
    }
}
const state = getDefaultState()

const mutations = {
    SET_VIDEOS: (state, videolist) => {
      state.videolist = videolist
    },
}

const actions = {
  setVideos({commit}, videos){
    commit("SET_VIDEOS",videos)
  },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

