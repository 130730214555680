import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/Layout/index.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: "/guide",
        name: "guide",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/components/guide/index.vue"),
      },
    ]
  },
]
const router = new Router({
  // mode: "history",
  base: "/guide",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach(async (to, from, next) => {
  // determine whether the user has logged in
  // const hasToken = getToken()
  if (to.path == "/") {
    next({ path: '/guide' })
  } else {
    next();
  }
})
export default router
