<template>
  <div>
      <router-view />
  </div>
</template>

<script>

export default {
  name: "index",

  data() {
    return {

    }
  },

}
</script>

<style scoped lang="scss">

</style>
